import React, { FunctionComponent, useContext } from "react";
import Accessibility from "../Accessibility";
import { LocalizedContext } from "../../services/LocalizedContextService";
import { ImageInterface } from "../../types/SanityTypes";
import sanityImageUrlBuilder from "../../utils/imageUrlBuilder";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";

interface LogoInterface {
  logoImage: ImageInterface;
}

const Logo: FunctionComponent<LogoInterface> = ({ logoImage }) => {
  const { logo } = useContext(LocalizedContext).sanityAccessibility || {};
  const { sanityId, sanityDataset } = useSiteMetadata();
  const urlBuilder = sanityImageUrlBuilder({
    projectId: sanityId,
    dataset: sanityDataset
  });

  return (
    <React.Fragment>
      <div data-testid="logo" style={{ width: "70px", height: "70px" }}>
        <picture
          className="placeholder"
          style={{
            paddingTop: `calc(100% / ${logoImage.asset.metadata?.dimensions.aspectRatio})`,
            background: "transparent",
            backgroundSize: "cover"
          }}
        >
          <img
            width={"70"}
            height={"70"}
            src={
              urlBuilder
                .image(logoImage as ImageInterface)
                .auto("format")
                .width(140)
                .height(140)
                .format("webp")
                .url() || undefined
            }
            alt={logoImage.alt}
            className="img-fluid"
          />
        </picture>
        <Accessibility location={logo || "logo"} />
      </div>
    </React.Fragment>
  );
};

export default Logo;

import { EventType, pushEvent } from "./index";

/**
 * Anchor Link Click Without href
 */

export const event66 = (text: string) => {

  pushEvent({
    eventInfo: {
      type: EventType.trackEvent,
      eventAction: EventType.anchorLinkClicked,
      eventLabel: `${text}`,
      eventValue: 1
    },
    category: {
      primaryCategory: EventType.custom
    }
  });
};

import { createContext } from "react";
import { LocalizedContextInterface } from "../types/SanityTypes";

export const emptyLocalizedContext: LocalizedContextInterface = {
  sanityAccessibility: {
    backToTop: "",
    downloadPDF: "",
    login: "",
    logo: "",
    newWindow: "",
    next: "",
    previous: "",
    readMore: "",
    search: "",
    skipToContent: "",
    skipToFooter: "",
    socialHeadingText: "",
    showContent: "",
    hideContent: "",
    play: "",
    externalLink: "",
    signUp: "",
    pause: "",
    unileverLogo: "",
    country: "",
    close: ""
  },
  sanityLabels: {},
  sanityMiappi: {
    folder: "",
    headline: {
      primaryText: "",
      secondaryText: "",
      tertiaryText: ""
    },
    miappiTag: "",
    socialLink: [
      {
        ctaURL: "",
        ctaLabel: ""
      }
    ]
  },
  sanityProductLandingPage: {
    _id: "",
    heading: "",
    slug: {
      current: ""
    }
  },
  sanityProductLandingPageV2: {
    _id: "",
    heading: "",
    slug: {
      current: ""
    }
  },
  sanityNewsletterSignUp: {
    _id: "",
    slug: {
      current: ""
    }
  },
  sanityNewsletterSignUpEpsilon: {
    _id: "",
    slug: {
      current: ""
    }
  },
  sanityHomePage: {
    _id: "",
    slug: {
      current: ""
    },
    _rawLogo: {
      _type: "",
      alt: "",
      asset: {
        _ref: "image-39e5b32b9a6e0aa476eb72f938184abbaf3833f6-2400x2400-png"
      }
    }
  },
  sanitySearch: {
    _id: "",
    name: "",
    _rawPlaceholder: {
      _type: "",
      alt: "",
      asset: {
        _ref: ""
      }
    },
    _rawSuccessfulSearch: [],
    _rawUnsuccessfulSearch: [],
    seo: {
      metaTitle: "",
      metaDescription: ""
    },
    slug: {
      current: ""
    },
    heading: ""
  },
  sanitySearchItems: {
    _id: "",
    name: "",
    seo: {
      metaTitle: "",
      metaDescription: ""
    },
    slug: {
      current: ""
    },
    heading: ""
  },
  sanityLocationSelector: {
    _id: "",
    slug: {
      current: ""
    }
  },
  sanitySocialMediaInformation: {
    socialLinks: []
  },
  sanitySiteSettings: {
    name: "",
    description: "",
    websiteTrademark: "",
    brandName: "",
    newrelic: {
      accountID: "",
      trustKey: "",
      agentID: "",
      licenseKey: "",
      applicationID: ""
    }
  },
  richWebTags: [],
  language: "",
  market: "",
  pageTranslations: [
    {
      language: "",
      urlPath: ""
    }
  ]
};

// @ts-ignore
export const LocalizedContext = createContext<LocalizedContextInterface>(emptyLocalizedContext);

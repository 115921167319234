import * as React from "react";
import { UnileverLogo } from "../../images/icons/unilever-logo";
import { LocalizedContext } from "../../services/LocalizedContextService";
import Accessibility from "../Accessibility";

interface props {
  linkToUnilever:string;
}

const UnileverIcon = ({linkToUnilever}: props) => {
  const { sanityAccessibility } = React.useContext(LocalizedContext);
  const { externalLink } = sanityAccessibility || {};

  return (
    <a href = {linkToUnilever}>    
        <Accessibility openNewWindow={true} location={externalLink} />
        <UnileverLogo />
    </a>
  );
};

export default UnileverIcon;

import React, { FunctionComponent } from "react";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import RichText from "../RichText";
// @ts-ignore
import ContextAwareAccordionToggle from "../ContextAwareAccordionToggle";

import "./styles.scss";
import { PortableText } from "../../types/SanityTypes";

export interface FAQBlockInterface {
  faqItems: ReadonlyArray<FaqItem>;
  heading?: string;
  bgColor?: {
    title: string;
    value: string;
  };
  cssClass?: string;
}

export type FaqItem = {
  question: string;
  _rawAnswer: PortableText;
  answer?: Record<string, unknown>;
  _key: string;
};

const FAQBlock: FunctionComponent<FAQBlockInterface> = ({ faqItems, heading, bgColor, cssClass }) => {
  const renderItems = () => {
    return faqItems.map((item, index) => {
      return (
        <Card key={index}>
          <ContextAwareAccordionToggle eventKey={item._key}>{item.question}</ContextAwareAccordionToggle>
          <Accordion.Collapse eventKey={item._key}>
            <Card.Body>
              <RichText data={item._rawAnswer || item.answer} />
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      );
    });
  };

  return (
    <div data-testid="faq-block" className={`faq-block ${cssClass} rx-theme-${bgColor?.title?.toLowerCase() || "default"}`}>
      {heading && <h2>{heading}</h2>}
      <Accordion>{renderItems()}</Accordion>
    </div>
  );
};

export default FAQBlock;

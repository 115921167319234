import React, { FunctionComponent, useContext } from "react";
import AccordionContext from "react-bootstrap/AccordionContext";
import { useAccordionToggle } from "react-bootstrap/AccordionToggle";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import { event66 } from "../../analytics/event66";

const ContextAwareAccordionToggle: FunctionComponent<{ eventKey: string }> = ({ children, eventKey }) => {
  const currentEventKey = useContext(AccordionContext);
  const decoratedOnClick = useAccordionToggle(eventKey, () => {
    event66("accordion - " + eventKey);
  });

  const isCurrentEventKey = currentEventKey === eventKey;

  const handleEnter = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      decoratedOnClick(event);
      handleScroll(event);
    }
  };

  return (
    <Accordion.Toggle
      role="button"
      as={Card.Header}
      aria-expanded={isCurrentEventKey ? "true" : "false"}
      onClick={event => [decoratedOnClick]}
      onKeyDown={handleEnter}
      eventKey={eventKey}
      tabIndex={0}
    >
      {children}
    </Accordion.Toggle>
  );
};

export default ContextAwareAccordionToggle;
function handleScroll(event: React.KeyboardEvent<Element>) {
  throw new Error("Function not implemented.");
}


import React, { FunctionComponent, useContext } from "react";
import { Search } from "../../images/icons/search";
import Accessibility from "../Accessibility";
import { LocalizedContext } from "../../services/LocalizedContextService";

const SearchIcon: FunctionComponent = () => {
  const { search } = useContext(LocalizedContext).sanityAccessibility || {};

  return (
    <div data-testid="search-icon" className="search-icon">
      <Search />
      <Accessibility location={search} />
    </div>
  );
};

export default SearchIcon;

import React, { FunctionComponent } from "react";
import RichTextImage from "../RichTextImage";
import RichText from "../RichText";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ImageInterface, PortableText } from "../../types/SanityTypes";
import "./styles.scss";

interface TimePeriodBlockInterface {
  data: {
    image: ImageInterface;
    year: string;
    subHeadline: string;
    textBody: PortableText;
    textAlignment: string;
  };
}

const TimePeriodBlock: FunctionComponent<TimePeriodBlockInterface> = ({ data }) => {
  const renderBlockOrder = () => {
    if (data.textAlignment === "left") {
      return (
        <>
          <Col lg={6} md={12} className="order-lg-2">
            <RichTextImage data={data.image} type={"about-us-image-text"} />
          </Col>
          <Col lg={6} md={12} className="order-lg-1" data-testid="text-left">
            <hr className="divider" />
            <h2>{data.year}</h2>
            <p>
              <strong>{data.subHeadline}</strong>
            </p>
            <RichText data={data.textBody} />
          </Col>
        </>
      );
    }
    return (
      <>
        <Col lg={6} md={12}>
          <RichTextImage data={data.image} type={"about-us-image-text"} />
        </Col>
        <Col lg={6} md={12} data-testid="text-right">
          <hr className="divider" />
          <h2>{data.year}</h2>
          <p>
            <strong>{data.subHeadline}</strong>
          </p>
          <RichText data={data.textBody} />
        </Col>
      </>
    );
  };

  return (
    <div className="time-period-block">
      <Row>{renderBlockOrder()}</Row>
    </div>
  );
};

export default TimePeriodBlock;

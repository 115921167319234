import React, { FunctionComponent } from "react";
import { Instagram } from "../../images/icons/instagram";
import { Twitter } from "../../images/icons/twitter";
import { Youtube } from "../../images/icons/youtube";
import { Facebook } from "../../images/icons/facebook";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./styles.scss";
import { ReactComponent } from "*.svg";
import { SocialMediaChannel } from "../../types/SanityTypes";
import Accessibility from "../Accessibility";

type SocialBlockProps = {
  socialLinks: ReadonlyArray<SocialMediaChannel>;
};

const SocialBlock: FunctionComponent<SocialBlockProps> = props => {
  const socialMedia = props.socialLinks;
  const socialIcons: { [char: string]: typeof ReactComponent } = {
    facebook: Facebook,
    instagram: Instagram,
    twitter: Twitter,
    youtube: Youtube,
    default: Facebook
  };

  const renderItems = () => {
    return socialMedia.map((item: SocialMediaChannel) => {
      const Component = socialIcons[item.channel.toLowerCase()] || socialIcons.default;
      const id = item.channel.toLowerCase().replace(/ /g, "-");
      return (
        <Col className="col-auto" key={item.channel}>
          <a href={item.url}  rel="noopener noreferrer" data-analytics-event48="" data-testid={id}>
            <Component />
            <Accessibility location={item.channel} openNewWindow={true} />
          </a>
        </Col>
      );
    });
  };

  if (!socialMedia) return null;

  return (
    <Row className="social-block no-gutters" data-testid="social-block">
      {renderItems()}
    </Row>
  );
};

export default SocialBlock;

import React, { FunctionComponent } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import "./styles.scss";
import { withPrefix } from "gatsby";

export interface LanguageToggleInterface {
  languages: string[];
  currentLanguage: string;
  translations: {
    language: string;
    urlPath: string;
  }[];
}

const LanguageToggle: FunctionComponent<LanguageToggleInterface> = ({ languages, currentLanguage, translations }) => {
  return (
    <>
      {languages.length >= 2 && (
        <div className="language-selector">
          <Row>
            {languages.map(lang => (
              <Col key={lang}>
                {currentLanguage === lang ? (
                  <p>{lang}</p>
                ) : translations.length > 0 ? (
                  translations.map(item => {
                    return (
                      <a className="in-active" href={withPrefix(item.urlPath)} key={`toggle-${item.language}`}>
                        <p>{item.language}</p>
                      </a>
                    );
                  })
                ) : (
                  <a className="in-active" href={withPrefix(`/${lang}/`)} key={`toggle-${lang}`}>
                    <p>{lang}</p>
                  </a>
                )}
              </Col>
            ))}
          </Row>
        </div>
      )}
    </>
  );
};

export default LanguageToggle;

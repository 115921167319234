import React from "react";

export const Youtube = () => {
  return (
    <svg width="64px" height="64px" viewBox="0 0 64 64" version="1.1">
      <title>Youtube</title>
      <g id="Symbols-yt" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-1234.000000, -95.000000)">
          <rect fill="none" x="0" y="0" width="1366" height="626"></rect>
          <g transform="translate(69.000000, 92.000000)"></g>
          <g transform="translate(964.000000, 95.000000)" fill="#FFFFFF">
            <path d="M307.343333,31.4433333 L299.856667,27.95 C299.203333,27.6466667 298.666667,27.9866667 298.666667,28.71 L298.666667,35.29 C298.666667,36.0133333 299.203333,36.3533333 299.856667,36.05 L307.34,32.5566667 C307.996667,32.25 307.996667,31.75 307.343333,31.4433333 L307.343333,31.4433333 Z M302,0 C284.326667,0 270,14.3266667 270,32 C270,49.6733333 284.326667,64 302,64 C319.673333,64 334,49.6733333 334,32 C334,14.3266667 319.673333,0 302,0 L302,0 Z M302,45 C285.62,45 285.333333,43.5233333 285.333333,32 C285.333333,20.4766667 285.62,19 302,19 C318.38,19 318.666667,20.4766667 318.666667,32 C318.666667,43.5233333 318.38,45 302,45 L302,45 Z"></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

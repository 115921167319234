export const identifyCountry = (countryCode: string, languageCode: string) => {
  const countries: Record<string, string> = {
    enSA: "Arabia",
    arSA: "Arabia",
    esAR: "Argentina",
    enAU: "Australia",
    ptBR: "Brasil",
    enCA: "Canada",
    frCA: "Canada",
    csCZ: "Česká Republika",
    esCL: "Chile",
    esCO: "Colombia",
    daDK: "Danmark",
    deDE: "Deutschland, Österreich & Schweiz",
    enEG: "Egypt",
    arEG: "Egypt",
    esES: "España",
    frFR: "France",
    frDE: "Allemagne, Autriche & Suisse",
    frAT: "Allemagne, Autriche & Suisse",
    frCH: "Allemagne, Autriche & Suisse",
    thTH: "ประเทศไทย",
    enIN: "India",
    idID: "Indonesia",
    huHU: "Magyarország",
    enMY: "Malaysia",
    esMX: "México",
    nlNL: "Nederland",
    deAT: "Deutschland, Österreich & Schweiz",
    esPE: "Peru",
    ruRU: "Россия",
    plPL: "Polska",
    ptPT: "Portugal",
    enPH: "Philippines",
    roRO: "România",
    deCH: "Deutschland, Österreich & Schweiz",
    skSK: "Slovensko",
    enZA: "South Africa",
    fiFI: "Suomi",
    svSE: "Sverige",
    trTR: "Türkiye",
    ukUA: "Україна",
    enGB: "United Kingdom",
    enUS: "United States",
    esUS: "Estados Unidos",
    esUY: "Uruguay",
    enNZ: "New Zealand"
  };
  return countries[languageCode?.toLowerCase() + countryCode?.toUpperCase()];
};

export const identifyLanguage = (languageCode: string) => {
  const language: Record<string, string> = {
    en: "English",
    fr: "French",
    es: "Spanish",
    ar: "Arabic"
  };

  return language[languageCode];
};

export const languagesFor = (country: string) => {
  const languages: Record<string, Array<string>> = {
    sa: ["en", "ar"],
    ar: ["es"],
    au: ["en"],
    br: ["pt"],
    ca: ["en", "fr"],
    cz: ["cs"],
    cl: ["es"],
    co: ["es"],
    dk: ["da"],
    de: ["de"],
    eg: ["en", "ar"],
    es: ["es"],
    fr: ["fr"],
    th: ["th"],
    in: ["en"],
    id: ["in"],
    hu: ["hu"],
    my: ["en"],
    mx: ["es"],
    nl: ["nl"],
    at: ["de"],
    pe: ["es"],
    ru: ["ru"],
    pl: ["pl"],
    pt: ["pt"],
    ph: ["en"],
    ro: ["ro"],
    ch: ["de"],
    sk: ["sk"],
    za: ["en"],
    fi: ["fi"],
    se: ["sv"],
    tr: ["tr"],
    gb: ["en"],
    us: ["en", "es"],
    uy: ["es"],
    nz: ["en"],
    ua: ["uk"],
  };

  return languages[country?.toLowerCase()];
};

export const secondaryLanguage = (countryCode: string) => {
  const languageArray = languagesFor(countryCode);
  if (languageArray.length > 1) {
    return languageArray[1];
  }
};

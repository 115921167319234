import React from "react";

export const Facebook = () => {
  return (
    <svg width="36px" height="36px" viewBox="0 0 27 27" version="1.1">
      <title>Share on Facebook</title>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-1158.000000, -159.000000)" fill="#140040">
          <path d="M1171.5,159 C1164.04406,159 1158,165.044062 1158,172.5 C1158,179.955938 1164.04406,186 1171.5,186 C1178.95594,186 1185,179.955938 1185,172.5 C1185,165.044062 1178.95594,159 1171.5,159 L1171.5,159 Z M1174.99861,168.260062 L1172.9996,168.260062 C1172.76272,168.260062 1172.49951,168.581702 1172.49951,169.009127 L1172.49951,170.49868 L1175,170.49868 L1174.62181,172.622938 L1172.49951,172.622938 L1172.49951,179 L1170.14031,179 L1170.14031,172.622938 L1168,172.622938 L1168,170.49868 L1170.14031,170.49868 L1170.14031,169.249285 C1170.14031,167.456675 1171.34554,166 1172.9996,166 L1174.99861,166 L1174.99861,168.260062 L1174.99861,168.260062 Z"></path>
        </g>
      </g>
    </svg>
  );
};

import React, { FunctionComponent } from "react";
import { Table } from "react-bootstrap";

export interface TableInterface {
  _key: string;
  rows: TableRowInterface[];
}

export interface TableRowInterface {
  _key: string;
  _type: string;
  cells: string[];
}

const TableBlock: FunctionComponent<{ data: TableInterface }> = ({ data }) => {
  return (
    <Table responsive className="table-block">
      <tbody>
        {data.rows.map((row: TableRowInterface, index: number) => {
          return index === 0 ? (
            <tr key={index}>
              {row.cells.map((tableHeading: string, index: number) => {
                return <th key={`heading-${row._key}-${index}`}>{tableHeading}</th>;
              })}
            </tr>
          ) : (
            <tr key={index}>
              {row.cells.map((tableRow: string, index: number) => {
                return <td key={`row-${row._key}-${index}`}>{tableRow}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default TableBlock;

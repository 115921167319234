import React, { FunctionComponent, useEffect } from "react";
import { EventType, pushEvent } from "../../../analytics";

interface AnalyticsProps {
  pageName: string;
  pageType: string;
  subCategories?: ReadonlyArray<string>;
  contentType: string;
  articleName?: string;
  richWebTags?: [];
}

const Analytics: FunctionComponent<AnalyticsProps> = props => {
  useEffect(() => {
    // @ts-ignore
    window.digitalData.page.category.subCategory1 = "";
    // @ts-ignore
    window.digitalData.page.category.subCategory2 = "";
    // @ts-ignore
    window.digitalData.page.category.subCategory3 = "";
    if (props.subCategories) {
      if (props.subCategories.length >= 1) {
        // @ts-ignore
        window.digitalData.page.category.subCategory1 = `${props.subCategories[0]}`;
      }
      if (props.subCategories.length >= 2) {
        // @ts-ignore
        window.digitalData.page.category.subCategory2 = `${props.subCategories[1]}`;
      }
      if (props.subCategories.length >= 3) {
        // @ts-ignore
        window.digitalData.page.category.subCategory3 = `${props.subCategories[2]}`;
      }
    }

    // @ts-ignore
    window.digitalData.page.dmpattributes = {};
    if (props.richWebTags && props.richWebTags?.length > 0) {
      // Create a mapping object for l3,l4
      const mappingTags = {};
      props.richWebTags.forEach(({ l3Hack, l4 }) => {
        if (mappingTags[l3Hack]) {
          // @ts-ignore
          mappingTags[l3Hack] = mappingTags[l3Hack] + "|" + l4;
        } else {
          mappingTags[l3Hack] = l4;
        }
      });

      // Create a unique list of L3 Tags, and append the L4, using the | as a separator if more than one.
      for (const mapping in mappingTags) {
        // @ts-ignore
        window.digitalData.page.dmpattributes[mapping] = mappingTags[mapping];
      }
      // Create a field called ‘master:’, and append all RWT tags in the format of “L1, L2,L3,L4| L1, L2,L3,L4, …” using the | as a separator
      // @ts-ignore
      window.digitalData.page.dmpattributes.master = props.richWebTags
        .map(({ l1, l2, l3, l4 }) => [l1, l2, l3, l4])
        .join("|");
    }

    // @ts-ignore
    window.digitalData.page.attributes.articleName = "";
    // @ts-ignore
    if (props.articleName) window.digitalData.page.attributes.articleName = `${props.articleName}`;
    // @ts-ignore
    window.digitalData.siteInfo.internalDomain = window.location.hostname;
    // @ts-ignore
    window.digitalData.page.pageInfo.pageName = `${props.pageName}`;
    // @ts-ignore
    window.digitalData.page.attributes.contentType = `${props.contentType}`;
    // @ts-ignore
    window.digitalData.page.category.pageType = `${props.pageType}`;

    pushEvent({
      eventInfo: {
        type: EventType.trackAjaxPageLoad,
        eventLabel: location.pathname,
        eventValue: 1
      },
      category: {
        primaryCategory: EventType.other
      },
      subcategory: EventType.read
    });
    // Only re-render if the pageName has changed. Otherwise, we risk sending multiple "trackAjaxPageLoad" events
    // when this component is re-rendered but the user is on the same page.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.pageName]);

  return <></>;
};

export default Analytics;

import * as React from "react";
import { Link as GatsbyLink } from "gatsby";
import { GatsbyLinkProps } from "gatsby-link";
import { FunctionComponent } from "react";
import { getLocalizedPath } from "../../utils/gatsbyBuild";

// We omit the "ref" property for this reason: https://github.com/gatsbyjs/gatsby/issues/16682#issuecomment-718155902
export interface LinkInterface extends Omit<GatsbyLinkProps<Record<string, unknown>>, "ref"> {
  _id?: string;
  external?: boolean;
}

export const Link: FunctionComponent<LinkInterface> = ({ _id, external, children, to, ...other }) => {
  const localizedPath = getLocalizedPath(to, _id);
  return external ? (
    <a href={to} rel="noreferrer" target="_blank" {...other}>
      {children}
    </a>
  ) : to.includes("http") || to.includes("#") ? (
    <a href={to} rel="noreferrer" {...other}>
      {children}
    </a>
  ) : (
    <GatsbyLink partiallyActive={true} activeClassName="active" to={localizedPath} {...other}>
      {children}
    </GatsbyLink>
  );
};

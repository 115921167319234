import { useStaticQuery, graphql } from "gatsby";

/**
 * Site metadata is defined in gatsby-config
 *
 * Language only needs to be passed if you are retrieving config values that change depending on the language being
 * used. These are defined under additionalLanguagesConfig below.
 *
 * For example:
 *
 * ```
 * const siteMetadata = {
 *   apiKey: "abc123"
 *   additionalLanguagesConfig: [
 *     {
 *       language: "fr",
 *        apiKey: "xyz789"
 *     },
 *     {
 *       language: "es",
 *       apiKey: "pqv456"
 *     }
 *   ]
 * }
 * ```
 *
 * Then:
 *
 * `const { apiKey } = useSiteMetadata("fr")  // apiKey is "xyz789"`
 */
export const useSiteMetadata = (language?: string) => {
  const { site } = useStaticQuery(
    graphql`
      query SiteMetaData {
        site {
          siteMetadata {
            analyticsAdobeAnalyticsId
            analyticsAdobeLaunchScriptName
            analyticsCountry
            analyticsGoogleAnalyticsId
            analyticsLanguage
            analyticsLocalBrand
            algoliaAppId
            algoliaIndex
            algoliaSearchApiKey
            bazaarvoiceEnv
            binConstCo
            binCartWire
            mikmakAppId
            prmApiKey
            prmApiUrl
            prmBrandCode
            prmCampaignId
            epsilonApiKey
            epsilonUrl
            epsilonDataSourceId
            epsilonSignUpCCID
            epsilonBrandCCID
            epsilonCorporateCCID
            evidonCompanyID
            evidonOCID
            reCaptchaSiteKey
            contactUsBrandId
            contactUsEnv
            contactUsFormType
            contactUsLocale
            contactUsQCDeployment
            contactUsQCLiveAgentInit
            contactUsQCDeploymentId
            contactUsQCOrgId
            contactUsQCButtonId
            intellibrandKeyToken
            htmlLang
            kritiqueApiKey
            kritiqueBrandId
            kritiqueLocaleId
            kritiqueRegion
            kritiqueSiteSource
            locale
            isoLanguage
            isoCountry
            extendedProfileApiUrl
            miappiClientId
            oneTrustDataDomainScriptId
            cookieQscriptURL
            cookieQlang
            cookieQORG
            sanityId
            sanityDataset
            searchV2
            siteName
            siteUrl
            smartLabel
            youtubeApiIframe
            additionalLanguagesConfig {
              language
              analyticsLanguage
              contactUsLocale
              htmlLang
              epsilonDataSourceId
              epsilonBrandCCID
              kritiqueApiKey
              kritiqueLocaleId
              kritiqueSiteSource
              isoLanguage
              locale
            }
            useShopalyst {
              link
              campaignId
              placementId
            }
          }
        }
      }
    `
  );

  /**
   * Override values in siteMetadata using the additionalLanguagesConfig object for a given language.
   */
  if (language) {
    //@ts-ignore
    site.siteMetadata.additionalLanguagesConfig.forEach(additionalLanguageConfig => {
      if (language === additionalLanguageConfig.language) {
        //@ts-ignore
        const { language, ...languageSpecificConfig } = additionalLanguageConfig;
        site.siteMetadata = { ...site.siteMetadata, ...languageSpecificConfig };
      }
    });
  }

  return site.siteMetadata;
};

import { EventType, pushEvent } from "./index";

/**
 * Click to purchase / Buy it Now
 */
export const event38 = (
  productId: string,
  productName: string,
  productCategoryName: string,
  brand: string,
  price?: string,
  quantity?: string
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const win = window as any;
  win.digitalData.product = [];
  win.digitalData.product.push({
    productInfo: {
      productID: productId,
      productName,
      price,
      brand,
      quantity
    },
    category: {
      primaryCategory: productCategoryName
    }
  });

  pushEvent({
    eventInfo: {
      type: EventType.trackEvent,
      eventAction: EventType.purchase,
      eventLabel: `Online - ${productName}`,
      eventValue: 1
    },
    category: {
      primaryCategory: EventType.conversion
    },
    subcategory: EventType.lead
  });
};

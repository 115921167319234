import React from "react";
import "./styles.scss";

export const Burger = () => {
  return (
    <svg width="40" height="24" viewBox="0 0 53 37" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>Menu</title>
      <g
        id="BATCH-1---Search-/-search-results-burger"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
      >
        <g id="Search-Result-Mobile" transform="translate(-33.000000, -182.000000)" stroke="#140041" strokeWidth="2.5">
          <g id="TOP-NAV-burger" transform="translate(18.000000, 166.000000)">
            <g id="burger" transform="translate(16.615385, 16.615385)">
              <line x1="0" y1="34.2692308" x2="51.9230769" y2="34.2692308" id="Line1"></line>
              <line x1="0" y1="17.6538462" x2="51.9230769" y2="17.6538462" id="Line2"></line>
              <line x1="0" y1="1.03846154" x2="51.9230769" y2="1.03846154" id="Line3"></line>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

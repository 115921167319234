/* eslint-disable react/display-name */
import React, { useContext } from "react";
// @ts-ignore
import BlockContent from "@sanity/block-content-to-react";
import ImageTextBlock from "../components/ImageTextBlock";
import VideoBlock from "../components/VideoBlock";
import RichTextImage from "../components/RichTextImage";
import RichTextMultipleImage from "../components/RichTextMultipleImage";
import TimePeriodBlock from "../components/TimePeriodBlock";
import FAQBlock, { FaqItem } from "../components/FAQBlock";
import CallOut from "../components/CallOut";
import SocialWall, { SocialWallInterface } from "../components/SocialWall";
import { ImageInterface, SanityColorList, Slug } from "../types/SanityTypes";
import { Link } from "../components/Link";
import ExternalLink from "../components/ExternalLink";
import TableBlock, { TableInterface } from "../components/TableBlock";
import { LocalizedContext } from "../services/LocalizedContextService";

type MultipleImageNode = {
  _key: string;
  _type: string;
  picture: ReadonlyArray<ImageInterface>;
  largeImage: string;
  smallImage: string;
};

type VideoNode = {
  _key: string;
  _type: string;
  _ref: string;
  _id: string;
  videoType: {
    name: string;
    _ref: string;
    _rev?: string;
  };
  video: {
    asset: {
      url: string;
    };
  };
  _rawCoverImage: ImageInterface;
  coverImage: ImageInterface;
  secondImage?: ImageInterface;
  _rawSecondImage?: ImageInterface;
  text: Record<string, unknown>[];
  _rawText?: Record<string, unknown>[];
  colorlist: SanityColorList;
};

type WorkoutVideoNode = {
  _key: string;
  _type: string;
  workoutVideo: {
    videoAsset: VideoNode;
    _ref: string;
    _id: string;
    workoutInfo?: {
      equipment: Record<string, unknown>[];
      focus: Record<string, unknown>[];
      level: string;
    };
  };
};

type TextNode = {
  _key: string;
  _type: string;
  text: string;
};

type ImageTextNode = {
  image: ImageInterface;
  text: readonly Record<string, unknown>[];
  textPosition: string;
  imageType: boolean;
};

type TimePeriodNode = {
  image: ImageInterface;
  textBody: readonly Record<string, unknown>[];
  textAlignment: string;
  year: string;
  subHeadline: string;
};

type FAQBlockNode = {
  faqItems: ReadonlyArray<FaqItem>;
  heading?: string;
};

type RichImageNode = {
  image: ImageInterface;
  imageType: {
    _ref?: string;
    name?: string;
  };
  linkURL?: string;
  _type: string;
};

type SocialWallNode = SocialWallInterface;

type TableNode = TableInterface;

type BlockProps = {
  children: ReadonlyArray<string>;
  node: NodeProps;
  options: Record<string, unknown>;
};

type NodeProps = {
  style: string;
  _key: string;
  _type: string;
  children: {
    text: string;
    _key: string;
    _type: string;
    marks: ReadonlyArray<string>;
  }[];
};

type ParentSection = Slug & {
  _id: string;
  name: string;
  main: {
    sectionColor: SanityColorList;
  };
  parentSection?: Slug & {
    name: string;
    main: {
      sectionColor: SanityColorList;
    };
  };
};

type MarkProps = {
  children: ReadonlyArray<string>;
  mark: {
    _key: string;
    _type: string;
    reference?: Record<string, unknown>;
    href?: string;
  };
  markKey: string;
  _key: string;
  _type: string;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const renderCenterText = (props: { children?: readonly string[]; node: any; options?: Record<string, unknown> }) => {
  return props.node.children[0].marks?.includes("center") ? "centerArticleText" : undefined;
};

export const RichTextSerializers = (pageSlug?: string, colorTheme?: string) => {
  const { sanityProductLandingPage, sanityProductLandingPageV2 } = useContext(LocalizedContext);

  return {
    types: {
      callOut: ({ node }: { node: TextNode }) => {
        return <CallOut text={node.text} backgroundColor={colorTheme as string} />;
      },
      imageBlock: ({ node }: { node: RichImageNode }) => {
        return (
          <RichTextImage
            data={node.image}
            type={node.imageType.name ? (node.imageType.name as string) : (node.imageType._ref as string)}
            linkURL={node?.linkURL}
          />
        );
      },
      imageTextBlock: ({ node }: { node: ImageTextNode }) => {
        return <ImageTextBlock data={node} />;
      },
      timePeriodBlock: ({ node }: { node: TimePeriodNode }) => {
        return <TimePeriodBlock data={node} />;
      },
      multipleImage: ({ node }: { node: MultipleImageNode }) => {
        return <RichTextMultipleImage data={node} />;
      },
      videoBlock: ({ node }: { node: VideoNode }) => {
        return <VideoBlock data={node} videoLink={pageSlug} />;
      },
      workoutVideoBlock: ({ node }: { node: WorkoutVideoNode }) => {
        return <VideoBlock data={node} videoLink={pageSlug} />;
      },
      faqs: ({ node }: { node: FAQBlockNode }) => {
        return <FAQBlock faqItems={node.faqItems} heading={node.heading} />;
      },
      faqBlock: ({ node }: { node: FAQBlockNode }) => {
        return <FAQBlock faqItems={node.faqItems} heading={node.heading} />;
      },
      miappi: ({ node }: { node: SocialWallNode }) => {
        return <SocialWall folder={node.folder} headline={node.headline} socialLink={node.socialLink} />;
      },
      table: ({ node }: { node: TableNode }) => {
        return <TableBlock data={node} />;
      },
      block: (props: BlockProps) => {
        const { style = "normal" } = props.node;

        if (style === "blockquote") {
          return (
            <blockquote>
              <p>
                {props.children.map((text, index) => {
                  return <span key={text + index}>{text}</span>;
                })}
              </p>
            </blockquote>
          );
        }

        if (style === "h2") {
          return (
            <div className={renderCenterText(props)}>
              <hr className="divider" />
              <h2>
                {props.children.map(text => {
                  return text;
                })}
              </h2>
            </div>
          );
        }

        if (style === "h3") {
          return (
            <h3 className={renderCenterText(props)}>
              {props.children.map(text => {
                return text;
              })}
            </h3>
          );
        }

        if (style === "normal") {
          return (
            <p className={renderCenterText(props)}>
              {props.children.map((text, index) => {
                return <span key={text + index}>{text}</span>;
              })}
            </p>
          );
        }

        return BlockContent.defaultSerializers.types.block(props);
      }
    },
    marks: {
      colorlist: (props: MarkProps) => {
        if (props.mark._type === "colorlist") {
          props.children.map((text, index) => {
            return <span key={text + index}>{text}</span>;
          });
        }
        return null;
      },
      center: (props: MarkProps) => {
        if (props.markKey === "center") {
          return props.children.map((text, index) => {
            return <span key={text + index}>{text}</span>;
          });
        }
        return null;
      },
      sup: (props: MarkProps) => {
        if (props.markKey === "sup") {
          return props.children.map((text, index) => {
            return <sup key={text + index}>{text}</sup>;
          });
        }
        return null;
      },
      internalLink: (props: MarkProps) => {
        if (props.mark._type === "internalLink") {
          const parentSection = props.mark.reference?.parentSection as ParentSection;
          const parentpath = `/${
            props.mark.reference?._type === "productV2" ||
            props.mark.reference?._type === "productLinePage" ||
            props.mark.reference?._type === "productLinePageV2"
              ? sanityProductLandingPage
                ? sanityProductLandingPage?.slug?.current
                : sanityProductLandingPageV2?.slug?.current
              : parentSection?.parentSection
              ? `${parentSection?.parentSection.slug.current}/${parentSection?.slug.current}`
              : parentSection?.slug.current
          }`;
          const currentPath = `/${(props.mark.reference?.slug as Record<string, unknown>)?.current}`;

          return props.children.map((text, index) => {
            return (
              <Link
                className="rx-internal-link"
                _id={props.mark.reference?._id as string}
                to={`${parentpath}${currentPath}`}
                key={text + index}
              >
                {text}
              </Link>
            );
          });
        }
      },
      link: (props: MarkProps) => {
        if (props.mark._type === "link") {
          return props.children.map((text, index) => {
            return (
              <React.Fragment key={(props?.mark?.href as string) + index}>
                <ExternalLink
                  hasIcon={false}
                  link={props.mark.href as string}
                  name={text}
                  noopener={true}
                  nofollow={false}
                  newWindow
                />
              </React.Fragment>
            );
          });
        }
      },
      linkNewWindow: (props: MarkProps) => {
        if (props.mark._type === "linkNewWindow") {
          return props.children.map((text, index) => {
            return (
              <React.Fragment key={(props?.mark?.href as string) + index}>
                <ExternalLink
                  hasIcon={true}
                  link={props.mark.href as string}
                  name={text}
                  noopener={true}
                  nofollow={false}
                  newWindow={true}
                />
              </React.Fragment>
            );
          });
        }
      }
    }
  };
};

import React, { FunctionComponent, useContext } from "react";
import { LocalizedContext } from "../../services/LocalizedContextService";

export interface AccessibilityInterface {
  location?: string;
  openNewWindow?: boolean;
}

const Accessibility: FunctionComponent<AccessibilityInterface> = ({ location, openNewWindow }) => {
  const { newWindow } = useContext(LocalizedContext).sanityAccessibility || {};
  return (
    <>
      {openNewWindow ? (
        <span className="sr-only sr-only-focusable">{location ? `${location} - ${newWindow}` : `${newWindow}`}</span>
      ) : (
        <span className="sr-only sr-only-focusable">{location}</span>
      )}
    </>
  );
};

export default Accessibility;

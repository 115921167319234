import React, { FunctionComponent } from "react";
import RichTextImage from "../RichTextImage";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { ImageInterface } from "../../types/SanityTypes";
import "./styles.scss";

interface RichTextMultipleImageInterface {
  data: {
    _key: string;
    _type: string;
    picture: ReadonlyArray<ImageInterface>;
    largeImage: string;
    smallImage: string;
  };
}

const RichTextMultipleImage: FunctionComponent<RichTextMultipleImageInterface> = ({ data }) => {
  const smallImagePosition = () => {
    return data.smallImage === "top" ? "top" : "bottom";
  };

  const renderMultipleImages = () => {
    if (data.largeImage === "first") {
      return (
        <>
          <Col
            lg={4}
            key={"sm-image"}
            data-testid="second-image-small"
            className={`small-image-second order-lg-2 ${smallImagePosition()}`}
            id={data.picture[0].asset.id}
          >
            <RichTextImage data={data.picture[1]} type="small" />
          </Col>
          <Col lg={8} key={"lrg-image"} data-testid="first-image-large" id={data.picture[1].asset?.id}>
            <RichTextImage data={data.picture[0]} type="large" />
          </Col>
        </>
      );
    } else if (data.largeImage === "second") {
      return (
        <>
          <Col
            lg={8}
            key={"lrg-image"}
            className="order-lg-2"
            data-testid="second-image-large"
            id={data.picture[0].asset.id}
          >
            <RichTextImage data={data.picture[1]} type="large" />
          </Col>
          <Col
            lg={4}
            key={"sm-image"}
            data-testid="first-image-small"
            className={`small-image-first ${smallImagePosition()}`}
            id={data.picture[1].asset.id}
          >
            <RichTextImage data={data.picture[0]} type="small" />
          </Col>
        </>
      );
    }
  };

  return (
    <Container fluid className="rich-text-multiple-image">
      <Row>{renderMultipleImages()}</Row>
    </Container>
  );
};

export default RichTextMultipleImage;

import React from "react";

export const Instagram = () => {
  return (
    <svg width="64px" height="64px" viewBox="0 0 64 64" version="1.1">
      <title>Instagram</title>
      <g id="Symbols-ig" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-1144.000000, -95.000000)">
          <rect fill="none" x="0" y="0" width="1366" height="626"></rect>
          <g transform="translate(69.000000, 92.000000)"></g>
          <g transform="translate(964.000000, 95.000000)" fill="#FFFFFF">
            <path d="M222,32 C222,37.5233333 217.523333,42 212,42 C206.476667,42 202,37.5233333 202,32 C202,31.43 202.06,30.8733333 202.163333,30.3333333 L198.666667,30.3333333 L198.666667,43.6566667 C198.666667,44.5833333 199.416667,45.3333333 200.343333,45.3333333 L223.66,45.3333333 C224.583333,45.3333333 225.333333,44.5833333 225.333333,43.6566667 L225.333333,30.3333333 L221.836667,30.3333333 C221.94,30.8733333 222,31.43 222,32 L222,32 Z M212,38.6666667 C215.68,38.6666667 218.666667,35.68 218.666667,32 C218.666667,28.32 215.68,25.3333333 212,25.3333333 C208.32,25.3333333 205.333333,28.32 205.333333,32 C205.333333,35.68 208.32,38.6666667 212,38.6666667 L212,38.6666667 Z M220,25 L223.996667,25 C224.55,25 225,24.55 225,24 L225,20.0033333 C225,19.45 224.55,19 223.996667,19 L220,19 C219.446667,19 218.996667,19.45 218.996667,20.0033333 L218.996667,24 C219,24.55 219.45,25 220,25 L220,25 Z M212,0 C194.326667,0 180,14.3266667 180,32 C180,49.6733333 194.326667,64 212,64 C229.673333,64 244,49.6733333 244,32 C244,14.3266667 229.673333,0 212,0 L212,0 Z M228.666667,44.9633333 C228.666667,47 227,48.6666667 224.963333,48.6666667 L199.036667,48.6666667 C197,48.6666667 195.333333,47 195.333333,44.9633333 L195.333333,19.0366667 C195.333333,17 197,15.3333333 199.036667,15.3333333 L224.963333,15.3333333 C227,15.3333333 228.666667,17 228.666667,19.0366667 L228.666667,44.9633333 L228.666667,44.9633333 Z"></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

import React, { FunctionComponent, useContext, useEffect, useState, useRef } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
// @ts-ignore
import BlockContent from "@sanity/block-content-to-react";
import sanityImageUrlBuilder from "../../utils/imageUrlBuilder";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import { ImageInterface, SanityColorList } from "../../types/SanityTypes";
import SocialShare from "../../components/SocialShare";
import RichText from "../../components/RichText";
import RichTextImage from "../../components/RichTextImage";
import { Play } from "../../images/icons/play";
import { useInView } from "react-intersection-observer";
// @ts-ignore
import { Player, ControlBar, ClosedCaptionButton } from "video-react";

import "./styles.scss";
import { LocalizedContext } from "../../services/LocalizedContextService";
import { event41 } from "../../analytics/event41";
import { event42 } from "../../analytics/event42";
import { event54 } from "../../analytics/event54";
import { event53 } from "../../analytics/event53";
import { event52 } from "../../analytics/event52";

export interface VideoInterface {
  _rawCoverImage: ImageInterface;
  _rawSecondImage?: ImageInterface;
  _rawText?: Record<string, unknown>[];
  video: {
    asset: {
      url: string;
    };
  };
  videoType: {
    name: string;
    _ref?: string;
  };
  colorlist: SanityColorList;
  // For Preview
  coverImage: ImageInterface;
  secondImage?: ImageInterface;
  text: Record<string, unknown>[];
}

export interface WorkoutVideoInterface {
  workoutVideo: {
    videoAsset: VideoInterface;
    _ref: string;
    _id: string;
    workoutInfo?: {
      equipment: Record<string, unknown>[];
      focus: Record<string, unknown>[];
      level: string;
      beginner?: string;
      intermediate?: string;
      expert?: string;
    };
  };
}

export interface WorkoutLabelsInterface {
  level: string;
  focus: string;
  equipment: string;
  beginner?: string;
  intermediate?: string;
  expert?: string;
}

export const PureVideoBlock: FunctionComponent<{
  data: WorkoutVideoInterface | VideoInterface;
  videoLink?: string;
  labels?: WorkoutLabelsInterface;
  language?: string;
}> = ({ data, videoLink, labels, language }) => {
  const { sanityId, sanityDataset, locale } = useSiteMetadata(language);
  const urlBuilder = sanityImageUrlBuilder({
    projectId: sanityId,
    dataset: sanityDataset
  });
  const [imageDisplay, setImageDisplay] = useState({ visibility: "visible" } as React.CSSProperties);

  const getLevel = () => {
    if ((data as WorkoutVideoInterface).workoutVideo.workoutInfo?.level === "beginner") {
      return labels?.beginner || (data as WorkoutVideoInterface).workoutVideo.workoutInfo?.level;
    } else if ((data as WorkoutVideoInterface).workoutVideo.workoutInfo?.level === "intermediate") {
      return labels?.intermediate || (data as WorkoutVideoInterface).workoutVideo.workoutInfo?.level;
    } else if ((data as WorkoutVideoInterface).workoutVideo.workoutInfo?.level === "expert") {
      return labels?.expert || (data as WorkoutVideoInterface).workoutVideo.workoutInfo?.level;
    }
    return labels?.expert || (data as WorkoutVideoInterface).workoutVideo.workoutInfo?.level;
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: "5px 0px"
  });

  const player = useRef(null) as any;

  const videoTheme = (data as WorkoutVideoInterface).workoutVideo
    ? {
        videoType:
          (data as WorkoutVideoInterface).workoutVideo.videoAsset.videoType.name ||
          (data as WorkoutVideoInterface).workoutVideo.videoAsset.videoType._ref,
        transcript: (data as unknown as string||undefined),
        videoUrl: (data as WorkoutVideoInterface).workoutVideo.videoAsset.video.asset.url,
        coverImageAlt: (data as WorkoutVideoInterface).workoutVideo.videoAsset.coverImage.alt,
        coverImage: (data as WorkoutVideoInterface).workoutVideo.videoAsset.coverImage,
        text:
          (data as WorkoutVideoInterface).workoutVideo.videoAsset._rawText ||
          (data as WorkoutVideoInterface).workoutVideo.videoAsset.text,
        themeColor: (data as WorkoutVideoInterface).workoutVideo.videoAsset.colorlist.value || "var(--primary-color)"
      }
    : {
        videoType: (data as VideoInterface).videoType?.name || (data as VideoInterface).videoType?._ref,
        videoUrl: (data as VideoInterface).video?.asset.url,
        coverImageAlt: (data as VideoInterface).coverImage?.alt,
        coverImage: (data as VideoInterface).coverImage,
        text: (data as VideoInterface).text,
        themeColor: (data as VideoInterface).colorlist?.value || "var(--primary-color)",
        secondImage: (data as VideoInterface).secondImage
      };
  const renderType = () => {
    // For Preview functionality
    if (videoTheme.videoType === "ae2e49d3-3246-4cc7-8c04-3365a22a373b") return "landscape-right";
    if (videoTheme.videoType === "ecc9907c-16b9-4515-b659-8d5a34c3893d") return "square-centre";
    if (videoTheme.videoType === "ecd4bb24-1b32-45bc-a6cf-173bd35d1a41") return "square-right";
    if (videoTheme.videoType === "f3d4289f-99a5-4ee2-9dfc-dbe69cadfb41") return "landscape-left";
    if (videoTheme.videoType === "f76135f1-c567-4ec6-8382-7b28cf62aae3") return "square-left";
    // For Page render
    return videoTheme.videoType?.toLowerCase();
  };

  const renderWorkoutInfo = (info: Record<string, unknown>[]) => {
    const infoArray: Array<string>[] = [];
    info.map(item => infoArray.push(item.name as string[]));
    return infoArray.join(", ");
  };

  const workoutDetails = (data as WorkoutVideoInterface).workoutVideo ? (
    <div className="info-heading">
      <Row>
        <div className="workout-heading" style={{ color: videoTheme.themeColor }}>
          {labels?.level}:
        </div>
        <p className="workout-p">{getLevel()}</p>
      </Row>
      <Row>
        <div className="workout-heading" style={{ color: videoTheme.themeColor }}>
          {labels?.focus}:
        </div>
        <p className="workout-p">
          {renderWorkoutInfo(
            (data as WorkoutVideoInterface).workoutVideo.workoutInfo?.focus as Record<string, unknown>[]
          )}
        </p>
      </Row>
      <Row>
        <div className="workout-heading" style={{ color: videoTheme.themeColor }}>
          {labels?.equipment}:
        </div>
        <p className="workout-p">
          {renderWorkoutInfo(
            (data as WorkoutVideoInterface).workoutVideo.workoutInfo?.equipment as Record<string, unknown>[]
          )}
        </p>
      </Row>
    </div>
  ) : null;

  useEffect(() => {
    let previousPosition = 0;
    const title = videoTheme.coverImageAlt;
    const videoAssetId = videoTheme.videoUrl;

    const onPlay = () => {
      event41(title, videoAssetId);
      // If the user pauses, we don't want to track subsequent play events
      player?.current?.video?.video?.removeEventListener("play", onPlay);
    };
    const onEnded = () => {
      event42(title, videoAssetId);
      // If the user watches the video again, track it
      player?.current?.video?.video?.addEventListener("play", onPlay);
    };
    const onTimeUpdate = () => {
      let currentPosition = 0;
      if (player?.current?.video?.video?.currentTime && player?.current?.video?.video?.duration) {
        currentPosition = player?.current?.video?.video?.currentTime / player?.current?.video?.video?.duration;
      }
      if (currentPosition >= 0.75 && previousPosition < 0.75) {
        event54(title, videoAssetId);
      }
      if (currentPosition >= 0.5 && previousPosition < 0.5) {
        event53(title, videoAssetId);
      }
      if (currentPosition >= 0.25 && previousPosition < 0.25) {
        event52(title, videoAssetId);
      }
      previousPosition = currentPosition;
    };

    player?.current?.video?.video?.addEventListener("play", onPlay);
    player?.current?.video?.video?.addEventListener("ended", onEnded);
    player?.current?.video?.video?.addEventListener("timeupdate", onTimeUpdate);

    return () => {
      player?.current?.video?.video?.removeEventListener("play", onPlay);
      player?.current?.video?.video?.removeEventListener("ended", onEnded);
      player?.current?.video?.video?.removeEventListener("timeupdate", onTimeUpdate);
    };
  });

  const toggleVideo = (id: string) => {
    setImageDisplay({ visibility: "hidden" });
    player?.current?.play();
  };

  const landscapeCoverImage = (image: ImageInterface, imageAlt: string) => {
    return (
      <>
        <picture className="placeholder">
          <source
            media={"(min-width: 1200px)"}
            srcSet={
              urlBuilder.image(image).auto("format").quality(80).width(1350).height(780).format("webp").url() ||
              undefined
            }
          />
          <source
            media={"(min-width: 768px)"}
            srcSet={
              urlBuilder.image(image).auto("format").quality(80).width(800).height(460).format("webp").url() ||
              undefined
            }
          />
          <img
            src={
              urlBuilder.image(image).auto("format").quality(80).width(700).height(400).format("webp").url() ||
              undefined
            }
            alt={imageAlt}
            loading={"lazy"}
            className="landscape-cover-image"
          />
        </picture>
        <div className="iconContainer">
          <div className="landscape-play-icon">
            <Play />
          </div>
        </div>
      </>
    );
  };

  const blockContent = (data as WorkoutVideoInterface).workoutVideo ? undefined : "block-content";

  const headingColor = () => {
    return (data as WorkoutVideoInterface).workoutVideo ? null : (
      <style>
        {`
          .block-content h2 {color: ${videoTheme.themeColor}}
        `}
      </style>
    );
  };

  const renderVideo = (containerClass: string, id: string, videoType: string) => {
    return (
      <div className={containerClass}>
        {inView ? (
          <div className={videoType} id={id}>
            <Player ref={player}>
              <source src={videoTheme.videoUrl} type="video/mp4" />
              <track kind="captions" src={(videoTheme.transcript)} label="English" default />
              <ControlBar autoHide={false}>
                <ClosedCaptionButton order={7} />
              </ControlBar>
            </Player>
          </div>
        ) : null}
      </div>
    );
  };

  const renderLandscapeVideoAndImage = (imageClassId: string, videoClassId: string) => {
    return (
      <>
        <div onClick={() => toggleVideo(videoTheme.coverImageAlt)} className={imageClassId} style={imageDisplay}>
          {landscapeCoverImage(videoTheme.coverImage, videoTheme.coverImageAlt)}
        </div>
        {renderVideo(videoClassId, videoTheme.coverImageAlt, "landscape-video")}
      </>
    );
  };

  const renderLandscapeTextBox = (textBoxPosition: string, workoutTestId: string) => {
    return (
      <div className={"text-box workout " + textBoxPosition} style={{ borderColor: videoTheme.themeColor }}>
        <div className="align-self-center">
          {headingColor()}
          <div className={blockContent}>
            <BlockContent blocks={videoTheme.text} />
          </div>
          {(data as WorkoutVideoInterface).workoutVideo && (
            <div data-testid={workoutTestId} className="info">
              {workoutDetails}
            </div>
          )}
          <SocialShare url={videoLink || videoTheme.videoUrl} locale={locale} />
        </div>
      </div>
    );
  };

  const squareVideoAndImage = (
    <>
      <div onClick={() => toggleVideo(videoTheme.coverImageAlt)} className="square-cover-image" style={imageDisplay}>
        <RichTextImage data={videoTheme.coverImage} type="square-video-image" />
        <div className="square-play-icon">
          <Play />
        </div>
      </div>
      <div>{renderVideo("video-container", videoTheme.coverImageAlt, "square-video")}</div>
    </>
  );

  if (renderType() === "landscape-right") {
    return (
      <Row ref={ref} className="no-gutters d-flex video-block" data-testid="landscape-right">
        <Col lg={8} md={12} className="order-lg-2" style={{ height: "fit-content" }}>
          {renderLandscapeVideoAndImage("right-landscape-image", "right-video")}
        </Col>
        <Col lg={4} md={12}>
          {renderLandscapeTextBox("text-box-left", "workout-right")}
        </Col>
      </Row>
    );
  }

  if (renderType() === "landscape-left") {
    return (
      <Row ref={ref} className="no-gutters landscape-left-block video-block" data-testid="landscape-left">
        <Col lg={8} md={12} className="left-landscape-vid-top-margin" style={{ height: "fit-content" }}>
          {renderLandscapeVideoAndImage("left-landscape-image", "left-video")}
        </Col>
        <Col lg={4} md={12} className="left-landscape-text-top-margin">
          {renderLandscapeTextBox("text-box-right", "workout-left")}
        </Col>
      </Row>
    );
  }

  if (renderType() === "square-left") {
    return (
      <Container fluid ref={ref} className="image-text-block square-left-block video-block " data-testid="square-left">
        <Row className="d-flex">
          <Col lg={6} md={12}>
            {squareVideoAndImage}
          </Col>
          <Col lg={6} md={12}>
            <RichText data={videoTheme.text} />
          </Col>
        </Row>
      </Container>
    );
  }

  if (renderType() === "square-centre") {
    return (
      <Container
        fluid
        ref={ref}
        className="image-text-block square-centre-block video-block"
        data-testid="square-centre"
      >
        <Row className="d-flex">
          <Col lg={3} className="order-lg-2 second-image" data-testid="square-centre-small">
            {videoTheme.secondImage && <RichTextImage data={videoTheme.secondImage as ImageInterface} type="small" />}
          </Col>
          <Col lg={{ offset: 3 }} md={12}>
            {squareVideoAndImage}
            <div className="square-text-position">
              <RichText data={videoTheme.text} />
            </div>
          </Col>
        </Row>
      </Container>
    );
  }

  if (renderType() === "square-right") {
    return (
      <Container fluid ref={ref} className="image-text-block square-right-block video-block" data-testid="square-right">
        <Row className="d-flex">
          <Col lg={{ offset: 3 }} className="second-image" data-testid="square-right-small">
            <RichTextImage data={videoTheme.secondImage as ImageInterface} type="small" />
          </Col>
          <Col lg={6} md={12}>
            {squareVideoAndImage}
            <div className="square-text-position">
              <RichText data={videoTheme.text} />
            </div>
          </Col>
        </Row>
      </Container>
    );
  }

  return null;
};

const VideoBlock: FunctionComponent<{ data: WorkoutVideoInterface | VideoInterface; videoLink?: string }> = ({
  data,
  videoLink
}) => {
  const { language, sanityLabels } = useContext(LocalizedContext);
  return <PureVideoBlock data={data} videoLink={videoLink} labels={sanityLabels?.workoutLabels} language={language} />;
};

export default VideoBlock;

import { EventType, pushEvent } from "./index";

/**
 * Share Click
 */
export const event65 = (eventName: string) => {
  pushEvent({
    eventInfo: {
      type: EventType.trackEvent,
      eventAction: EventType.share,
      eventLabel: eventName,
      eventValue: 1
    },
    category: {
      primaryCategory: EventType.referral
    },
    subcategory: EventType.lead
  });
};

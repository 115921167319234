import React, { FunctionComponent, useContext } from "react";
import { User } from "../../images/icons/user";
import Accessibility from "../Accessibility";
import { LocalizedContext } from "../../services/LocalizedContextService";

const ProfileIcon: FunctionComponent = () => {
  const { login } = useContext(LocalizedContext).sanityAccessibility || {};

  return (
    <button type="button" className="profile-login" data-testid="profile-login" aria-label="Toggle profile menu">
      <User />
      <Accessibility location={login} />
    </button>
  );
};

export default ProfileIcon;

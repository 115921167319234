import React, { FunctionComponent } from "react";
import sanityImageUrlBuilder from "../../utils/imageUrlBuilder";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import { ImageInterface } from "../../types/SanityTypes";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "web-common/src/components/Link";
import "./styles.scss";

interface RichTextImageInterface {
  data?: ImageInterface;
  type: string;
  imageWidth?: number;
  imageHeight?: number;
  linkURL?: string;
}

const RichTextImage: FunctionComponent<RichTextImageInterface> = ({ data, type, imageHeight, imageWidth, linkURL }) => {
  const { sanityId, sanityDataset } = useSiteMetadata();
  const urlBuilder = sanityImageUrlBuilder({
    projectId: sanityId,
    dataset: sanityDataset
  });

  const defineImageWidth = (defaultValue: number) => {
    return imageWidth || defaultValue;
  };
  const defineImageHeight = (defaultValue: number) => {
    return imageHeight || defaultValue;
  };

  const renderType = () => {
    // For Preview functionality
    if (type === "a3946576-2951-4742-9522-165528368c3e") return "type a";
    if (type === "d169a714-2f10-4172-83e7-476463be90fe") return "type b";
    if (type === "06340ca4-00b4-4477-a161-199563ceee04") return "type c";
    // For Page render
    return type.toLowerCase();
  };

  const displayPlaceholder = () => {
    if (data?.asset?.metadata)
      return {
        background: `url(${data.asset.metadata.lqip})`,
        backgroundSize: "cover"
      };
  };

  const squareImage = (
    <figure className="square-placeholder" style={displayPlaceholder()}>
      <source
        media="screen and (min-width: 1200px)"
        srcSet={`${urlBuilder
          .image(data as ImageInterface)
          .quality(80)
          .auto("format")
          .format("webp")
          .width(defineImageWidth(680))
          .height(defineImageHeight(680))}`}
      />
      <source
        media="screen and (min-width: 768px)"
        srcSet={`${urlBuilder
          .image(data as ImageInterface)
          .quality(80)
          .auto("format")
          .format("webp")
          .width(defineImageWidth(600))
          .height(defineImageHeight(600))
          .url()}`}
      />
      <img
        src={
          urlBuilder
            .image(data as ImageInterface)
            .auto("format")
            .quality(80)
            .format("webp")
            .width(defineImageWidth(480))
            .height(defineImageHeight(480))
            .url() as string
        }
        alt={data?.alt}
        loading={"lazy"}
        className="square-image"
      />
    </figure>
  );
  if (renderType() === "about-us-image-text") {
    return (
      <div className="rich-text-image">
        <figure className="placeholder" style={displayPlaceholder()}>
          <source
            media="screen and (min-width: 1200px)"
            srcSet={`${urlBuilder
              .image(data as ImageInterface)
              .auto("format")
              .format("webp")
              .quality(80)
              .width(900)
              .height(820)}`}
          />
          <source
            media="screen and (min-width: 768px)"
            srcSet={`${urlBuilder
              .image(data as ImageInterface)
              .auto("format")
              .quality(80)
              .width(800)
              .height(720)
              .format("webp")
              .url()}`}
          />
          <img
            src={
              urlBuilder
                .image(data as ImageInterface)
                .auto("format")
                .quality(80)
                .width(780)
                .height(700)
                .format("webp")
                .url() as string
            }
            alt={data?.alt}
            loading={"lazy"}
            className="image-text"
          />
        </figure>
      </div>
    );
  }

  if (renderType() === "image-text") {
    return (
      <div className="rich-text-image" data-testid="rich-text-image">
        <figure className="placeholder" style={displayPlaceholder()}>
          <source
            media="screen and (min-width: 1200px)"
            srcSet={`${urlBuilder
              .image(data as ImageInterface)
              .auto("format")
              .format("webp")
              .quality(80)
              .width(580)
              .height(680)}`}
          />
          <source
            media="screen and (min-width: 768px)"
            srcSet={`${urlBuilder
              .image(data as ImageInterface)
              .auto("format")
              .quality(80)
              .width(500)
              .height(600)
              .format("webp")
              .url()}`}
          />
          <img
            src={
              urlBuilder
                .image(data as ImageInterface)
                .auto("format")
                .quality(80)
                .width(480)
                .height(580)
                .format("webp")
                .url() as string
            }
            alt={data?.alt}
            loading={"lazy"}
            className="image-text"
          />
        </figure>
      </div>
    );
  }

  if (renderType() === "square-video-image") {
    return (
      <div className="rich-text-image" data-testid="square-video-image">
        {squareImage}
      </div>
    );
  }

  if (renderType() === "type c" || renderType() === "square-right") {
    return (
      <div className="rich-text-image" data-testid="square-right">
        <Row>
          <Col lg={{ offset: 6 }} md={12} className="order-lg-2">
            {squareImage}
          </Col>
        </Row>
      </div>
    );
  }

  if (renderType() === "type b" || renderType() === "square-left") {
    return (
      <div className="rich-text-image" data-testid="square-left">
        <Row>
          <Col lg={6} md={12} className="order-lg-2">
            {squareImage}
          </Col>
        </Row>
      </div>
    );
  }

  if (renderType() === "type a" || renderType() === "full-width") {
    return (
      <div className="rich-text-image" data-testid="full-width" style={{ textAlign: "center" }}>
        <Row>
          {linkURL ? (
            <Link _id={linkURL} to={linkURL as string}>
              <Col lg={{ offset: 1 }}>
                <figure>
                  <source
                    media="screen and (min-width: 1200px)"
                    srcSet={`${urlBuilder
                      .image(data as ImageInterface)
                      .auto("format")
                      .format("webp")
                      .quality(80)
                      .width(1100)
                      .height(680)}`}
                  />
                  <img
                    src={
                      urlBuilder
                        .image(data as ImageInterface)
                        .auto("format")
                        .format("webp")
                        .quality(80)
                        .width(820)
                        .height(530)
                        .url() as string
                    }
                    alt={data?.alt}
                    loading={"lazy"}
                    className="full-width"
                  />
                </figure>
              </Col>
            </Link>
          ) : (
            <Col lg={{ offset: 1 }}>
              <figure>
                <source
                  media="screen and (min-width: 1200px)"
                  srcSet={`${urlBuilder
                    .image(data as ImageInterface)
                    .auto("format")
                    .format("webp")
                    .quality(80)
                    .width(1100)
                    .height(680)}`}
                />
                <img
                  src={
                    urlBuilder
                      .image(data as ImageInterface)
                      .auto("format")
                      .format("webp")
                      .quality(80)
                      .width(820)
                      .height(530)
                      .url() as string
                  }
                  alt={data?.alt}
                  loading={"lazy"}
                  className="full-width"
                />
              </figure>
            </Col>
          )}
          <Col lg={1}></Col>
        </Row>
      </div>
    );
  }

  if (renderType() === "large") {
    return (
      <div className="rich-text-image" data-testid="rich-text-image-large">
        <figure className="placeholder" style={displayPlaceholder()}>
          <source
            media="screen and (min-width: 992px)"
            srcSet={`${urlBuilder
              .image(data as ImageInterface)
              .auto("format")
              .quality(80)
              .width(700)
              .height(500)
              .format("webp")
              .url()}`}
          />
          <source
            media="screen and (min-width: 768px)"
            srcSet={`${urlBuilder
              .image(data as ImageInterface)
              .auto("format")
              .quality(80)
              .width(600)
              .height(500)
              .format("webp")
              .url()}`}
          />
          <img
            src={
              urlBuilder
                .image(data as ImageInterface)
                .auto("format")
                .quality(80)
                .width(600)
                .height(500)
                .format("webp")
                .url() as string
            }
            alt={data?.alt}
            loading={"lazy"}
            className="large-image"
          />
        </figure>
      </div>
    );
  }

  if (renderType() === "small") {
    return (
      <div className="rich-text-image small" data-testid="rich-text-image-small">
        <figure className="small-placeholder" style={displayPlaceholder()}>
          <source
            media="screen and (min-width: 992px)"
            srcSet={`${urlBuilder
              .image(data as ImageInterface)
              .auto("format")
              .quality(80)
              .width(500)
              .height(500)
              .format("webp")
              .url()}`}
          />
          <source
            media="screen and (min-width: 768px)"
            srcSet={`${urlBuilder
              .image(data as ImageInterface)
              .auto("format")
              .quality(80)
              .width(400)
              .height(400)
              .format("webp")
              .url()}`}
          />
          <img
            src={
              urlBuilder
                .image(data as ImageInterface)
                .auto("format")
                .quality(80)
                .width(400)
                .height(400)
                .format("webp")
                .url() as string
            }
            alt={data?.alt}
            loading={"lazy"}
            className="small-image"
          />
        </figure>
      </div>
    );
  }

  return null;
};

export default RichTextImage;

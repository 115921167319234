import React, { FunctionComponent } from "react";

import "./styles.scss";

const CallOut: FunctionComponent<CallOutInterface> = ({ backgroundColor, text }) => {
  const gradients: { [key: string]: string } = {
    Teal: "linear-gradient(46deg, var(--get-mentored-blue-color) 0%, var(--get-mentored-green-color) 100%)",
    Purple: "linear-gradient(28deg, var(--pink-light-color) 0%, var(--purple-light-color) 100%)",
    Pink: "var(--pink-bright-color)",
    Red: "linear-gradient(300deg, var(--orange-color) 0%, var(--pink-medium-color) 100%)",
    Primary: "var(--primary-color)",
    Blue: "var(--blue-dark-color)"
  };

  return (
    <div
      style={{ background: gradients[backgroundColor as string] || "var(--blue-dark-color)" }}
      data-testid="call-out"
      className="call-out"
    >
      <div className="text-position">{text}</div>
    </div>
  );
};

export default CallOut;

interface CallOutInterface {
  backgroundColor?: string;
  text: string;
}

import React, { FunctionComponent } from "react";
import ProfileIcon from "web-common/src/components/Header/profileIcon";
import { InternalLink } from "web-common/src/types/SanityTypes";
import { Link } from "web-common/src/components/Link";

interface ProfileToggleInterface {
  data: {
    sanityNewsletterSignUpEpsilon?: InternalLink;
  };
}

const ProfileToggle: FunctionComponent<ProfileToggleInterface> = ({ data }) => {
  return (
    <>
      {data.sanityNewsletterSignUpEpsilon && (
        <Link
          to={data.sanityNewsletterSignUpEpsilon.slug.current}
          _id={data.sanityNewsletterSignUpEpsilon._id}
          data-analytics-event57=""
        >
          <ProfileIcon />
        </Link>
      )}
    </>
  );
};
export default ProfileToggle;

import React, { createRef, useEffect, FunctionComponent, useContext } from "react";
import { Helmet } from "react-helmet";
import { LocalizedContext } from "web-common/src/services/LocalizedContextService";
import { event38 } from "web-common/src/analytics/event38";
import { useSiteMetadata } from "web-common/src/hooks/useSiteMetadata";
import Button from "react-bootstrap/Button";
import "./styles.scss";

export interface MikmakInterface {
  productCode: string;
  productName: string;
  productCategory: string;
  brand: string;
}

export const MikmakScript = () => {
  const { mikmakAppId } = useSiteMetadata();

  return (
    <Helmet>
      <script>
        {`
          (function(e, d) {
            try {
                var a = window.swnDataLayer = window.swnDataLayer || {};
                a.appId = e || a.appId, a.eventBuffer = a.eventBuffer || [], a.loadBuffer = a.loadBuffer || [], a.push = a.push || function(e) {
                    a.eventBuffer.push(e)
                }, a.load = a.load || function(e) {
                    a.loadBuffer.push(e)
                }, a.dnt = a.dnt != null ? a.dnt : d;
                var t = document.getElementsByTagName("script")[0],
                    n = document.createElement("script");
                n.async = !0, n.src = "//wtb-tag.mikmak.ai/scripts/" + a.appId + "/tag.min.js", t.parentNode.insertBefore(n, t)
            } catch (e) {
                console.log(e)
            }
        }("${mikmakAppId}", false));
        `}
      </script>
    </Helmet>
  );
};

export const MikmakWidget: FunctionComponent<MikmakInterface> = ({ id }) => {
  return <div id="mikmak-widget" className="embed" data-eans={id}></div>;
};

export const MikmakButton: FunctionComponent<MikmakInterface> = ({
  productCode,
  productName,
  productCategory,
  brand
}) => {
  const { buyItNow } = useContext(LocalizedContext).sanityLabels?.productLabels || {};

  const mikmakButton = createRef<HTMLAnchorElement>();

  const onClick = () => {
    const waitForMikmak = setInterval(() => {
      mikmakButton.current?.click();
      event38(productCode, productName, productCategory, brand);
      clearInterval(waitForMikmak);
    }, 100);
  };

  return (
    <div className="mikmak">
      <Button
        tabIndex={0}
        className="swn-tag-wtb-btn buy-it-now-button btn btn-primary mikmak-button"
        onClick={onClick}
      >
        {buyItNow}
      </Button>

      <a
        aria-label="where to buy product search"
        className="hidden swn-tag-wtb-btn buy-it-now-button btn btn-primary mikmak-button"
        ps-sku={productCode}
        data-eans={productCode}
        ref={mikmakButton}
      >
        {buyItNow}
      </a>
    </div>
  );
};

export default MikmakScript;

import * as React from "react";
import { FunctionComponent, useEffect } from "react";
import "./styles.scss";
import {useSiteMetadata} from "web-common/src/hooks/useSiteMetadata";

export interface AdChoicesInterface {
  label: string;
}

const AdChoices: FunctionComponent<AdChoicesInterface> = ({ label }) => {
  const { evidonCompanyID, evidonOCID } = useSiteMetadata();
  useEffect(() => {
    if (document.getElementById('ad-choice-script')) return;
    const script = document.createElement('script');
    script.src = '/us/vendors.js';
    script.id = 'ad-choice-script';
    script.setAttribute('data-ev-tag-pid', evidonOCID);
    script.setAttribute('data-ev-tag-ocid', evidonCompanyID);
    script.setAttribute('async', 'true');
    script.onload = scriptLoaded;
    document.head.appendChild(script);
  }, [])

  const preventEvent = (event: Event) => {
    event.preventDefault();
  }

  const scriptLoaded = () => {
    const element = document.getElementsByClassName('evidon-consent-link')
    if (element.length > 0) {
      element[0].addEventListener("click", (event) => preventEvent(event));
    } else {
      setTimeout(() => {
        scriptLoaded()
      }, 1000)
    }
  }

  return (
    <div className="us-c-adchoice">
      <span className="evidon-notice-link"></span>
    </div>
  )
    ;
};

export default AdChoices;

import React from "react";

export const Close = () => {
  return (
    <svg width="33px" height="33px" viewBox="0 0 74 74" version="1.1">
      <title>Close</title>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-14.000000, -162.000000)">
          <rect id="Rectangle" x="0" y="0" />
          <g
            id="Close"
            transform="translate(51.000000, 199.000000) rotate(-225.000000) translate(-51.000000, -199.000000) translate(26.000000, 174.000000)"
            fill="#D8D8D8"
            stroke="#FFFFFF"
            strokeLinecap="round"
            strokeWidth="2.5"
          >
            <line x1="25.0681974" y1="0.325957817" x2="24.4318013" y2="49.3284565" id="Line-4" />
            <line x1="49.2512487" y1="24.5090091" x2="0.885146112" y2="24.5090091" id="Line-4-Copy" />
          </g>
        </g>
      </g>
    </svg>
  );
};

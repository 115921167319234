import React from "react";

export const ExternalLinkIcon = () => {
  return (
    <svg width="23px" height="16px" viewBox="0 0 23 24" version="1.1">
      <title>external-link</title>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-830.000000, -638.000000)">
          <g transform="translate(114.000000, 341.000000)">
            <g transform="translate(716.000000, 298.000000)">
              <g>
                <path
                  d="M13.67,0.703 L20.878,0.954 C21.5012678,0.9778719 21.9882578,1.50060431 21.968,2.124 L21.716,9.331 M21.081,1.858 L12.69,9.683"
                  stroke="#fff"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <g transform="translate(0.000000, 3.500000)">
                  <g>
                    <g />
                    <path
                      d="M17.227,15.602 C17.227,16.498 16.498,17.227 15.602,17.227 L3.792,17.227 C2.896,17.227 2.167,16.498 2.167,15.602 L2.167,3.792 C2.167,2.896 2.896,2.167 3.792,2.167 L8.613,2.167 L8.613,0 L3.792,0 C1.7,0 0,1.7 0,3.792 L0,15.602 C0,17.692 1.7,19.394 3.792,19.394 L15.602,19.394 C17.692,19.394 19.394,17.693 19.394,15.602 L19.394,10.78 L17.227,10.78 L17.227,15.602 Z"
                      fill="#fff"
                      fillRule="nonzero"
                      mask="url(#mask-2)"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

import React, { FunctionComponent, useState } from "react";
import useScript from "../../hooks/useScript";
import { Twitter } from "../../images/icons/twitterShare";
import { Facebook } from "../../images/icons/facebookShare";
import { Email } from "../../images/icons/emailShare";
import "./styles.scss";
import { Share } from "../../images/icons/share";
import { Close } from "../../images/icons/close2";
import Accessibility from "../Accessibility";
import { event65 } from "../../analytics/event65";

interface SocialShareInterface {
  url: string;
  locale: string;
  name?: string;
  expanded?: boolean;
  label?: string;
}

const SocialShare: FunctionComponent<SocialShareInterface> = ({
  url,
  locale,
  name,
  expanded = false,
  label = "Share"
}) => {
  const [socialShareExpanded, setSocialShareExpanded] = useState(false);
  const facebookUrl = "https://www.facebook.com/sharer/sharer.php?u=" + url + "%2F&amp;src=sdkpreparse";
  const twitterUrl = "https://twitter.com/intent/tweet?url=" + url;

  const handleClick = () => {
    setSocialShareExpanded(!socialShareExpanded);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useScript(`https://connect.facebook.net/${locale}/sdk.js#xfbml=1&version=v8.0`, "facebook-script");
  };

  const handleAnalytics = (eventName: string) => {
    setTimeout(() => {
      event65(eventName);
    }, 500);
  };

  return (
    <div className="social-container">
      {!expanded ? (
        <div
          role="button"
          tabIndex={0}
          className="display-share"
          aria-expanded={socialShareExpanded}
          onClick={handleClick}
          onKeyDown={(event: React.KeyboardEvent) => {
            if (event.key === "Enter") {
              handleClick();
            }
          }}
        >
          {socialShareExpanded ? <Close /> : <Share />}
        </div>
      ) : null}
      <div
        style={socialShareExpanded || expanded ? { display: "block" } : { display: "none" }}
        className={`display-social ${expanded ? "is-expanded" : ""}`}
        aria-expanded={socialShareExpanded}
      >
        {expanded ? (
          <h2 className="h4" style={{ display: "inline" }}>
            {label}
          </h2>
        ) : null}
        <div className="social-share" data-testid="social-share">
          <a
            href={facebookUrl}
            data-href={url}
            data-testid="facebook-social-share"
            rel="noopener noreferrer nofollow"
            className="icon"
            onClick={() => handleAnalytics("Facebook")}
          >
            <Facebook />
            <Accessibility location={"Facebook"} openNewWindow={true} />
          </a>
          <a
            href={twitterUrl}
            data-href={url}
            data-testid="twitter-social-share"
            rel="noopener noreferrer nofollow"
            className="icon"
            onClick={() => handleAnalytics("Twitter")}
          >
            <Twitter />
            <Accessibility location={"Twitter"} openNewWindow={true} />
          </a>
          <a
            href={`mailto:?subject=${name}&body=${url}`}
            data-testid="email-social-share"
            data-href={url}
            className="icon"
            onClick={() => handleAnalytics("E-mail")}
          >
            <Email />
            <Accessibility location={"Email"} openNewWindow={true} />
          </a>
        </div>
      </div>
    </div>
  );
};

export default SocialShare;

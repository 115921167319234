import React from "react";

export const Twitter = () => {
  return (
    <svg width="36px" height="36px" viewBox="0 0 27 27" version="1.1">
      <title>Share on Twitter</title>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-1191.000000, -159.000000)" fill="#140040">
          <path d="M1204.5,159 C1197.04406,159 1191,165.044062 1191,172.5 C1191,179.955938 1197.04406,186 1204.5,186 C1211.95594,186 1218,179.955938 1218,172.5 C1218,165.044062 1211.95594,159 1204.5,159 L1204.5,159 Z M1210.66967,169.490989 C1210.67531,169.600454 1210.67672,169.709919 1210.67672,169.816713 C1210.67672,173.154052 1207.9949,177 1203.08833,177 C1201.58166,177 1200.17938,176.5835 1199,175.865305 C1199.20738,175.889334 1199.4204,175.898678 1199.63625,175.898678 C1200.88616,175.898678 1202.03592,175.496863 1202.94867,174.818716 C1201.78199,174.797357 1200.79729,174.068482 1200.4573,173.067281 C1200.61953,173.095314 1200.786,173.111334 1200.95811,173.111334 C1201.20076,173.111334 1201.43776,173.08063 1201.66066,173.023228 C1200.44037,172.790949 1199.52056,171.771059 1199.52056,170.548258 L1199.52056,170.517554 C1199.8803,170.70578 1200.29224,170.820585 1200.72957,170.833934 C1200.01291,170.380056 1199.54314,169.608463 1199.54314,168.731411 C1199.54314,168.269523 1199.67434,167.834335 1199.90429,167.461888 C1201.22051,168.989054 1203.18426,169.992925 1205.40195,170.101055 C1205.3554,169.915499 1205.33283,169.723268 1205.33283,169.524363 C1205.33283,168.132025 1206.52632,167 1208.00054,167 C1208.76658,167 1209.46066,167.307035 1209.94737,167.798291 C1210.5554,167.683487 1211.12534,167.475237 1211.64026,167.185556 C1211.44135,167.774262 1211.01953,168.269523 1210.46793,168.583233 C1211.00825,168.520491 1211.52176,168.385663 1212,168.184088 C1211.64308,168.690028 1211.19023,169.135896 1210.66967,169.490989 L1210.66967,169.490989 Z"></path>
        </g>
      </g>
    </svg>
  );
};

import * as React from "react";
import { FunctionComponent, ReactNode, useEffect, useState } from "react";
import Header from "../Header";
import Footer from "../Footer";
import { Helmet } from "react-helmet";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import { StringParam, useQueryParam } from "use-query-params";
import Analytics from "../Analytics/DigitalData";
import OneTrust from "../OneTrust";
import CookieQ from "../CookieQ";
import NewRelic from "../NewRelic";
import Evidon from "../Evidon";
import AdobeLaunch from "../AdobeLaunch";
import { useStaticQuery, graphql } from "gatsby";
import { LocalizedContextInterface } from "../../types/SanityTypes";
import { LocalizedContext } from "../../services/LocalizedContextService";
import { MikmakScript } from "../Mikmak";

type LayoutProps = {
  children: ReactNode;
  pageName: string;
  pageType: string;
  localizedContext: LocalizedContextInterface;
  parentPages?: ReadonlyArray<string>;
  contentType?: string;
  cssClass?: string;
  language?: string;
  backgroundColor?: {
    title: string;
    value: string;
  };
  theme?: {
    name: string;
  };
};

const Layout: FunctionComponent<LayoutProps> = ({
  children,
  pageName,
  pageType,
  parentPages,
  contentType,
  localizedContext,
  cssClass,
  language,
  backgroundColor,
  theme
}) => {
  const [enableOneTrustParam] = useQueryParam("enableOneTrust", StringParam);
  const [enableOneTrust, setEnableOneTrust] = useState(false);
  const [enableAnalyticsParam] = useQueryParam("enableAnalytics", StringParam);
  const [enableAnalytics, setEnableAnalytics] = useState(false);
  const { htmlLang, isoCountry, market } = useSiteMetadata(localizedContext.language);

  const scriptsData = useStaticQuery(graphql`
    {
      allSanityCommonScript {
        nodes {
          market
          id
          name
          type
          async
          src
        }
      }
    }
  `);

  const filteredScriptsData = scriptsData.allSanityCommonScript.nodes.filter((node: any) => node.market === market);

  useEffect(() => {
    setEnableOneTrust(enableOneTrustParam != "false");
    setEnableAnalytics(enableAnalyticsParam != "false");
  }, [enableOneTrustParam, enableAnalyticsParam]);

  const pageClassName = (pageName: string) => {
    let className = "rx-theme--default";
    if (pageName?.toLowerCase().includes("metathon")) {
      className = "rx-campaign--metathon rx-theme--dark";
    } else if (pageName?.toLowerCase().includes("marathon")) {
      className = "rx-campaign--marathon";
    }

    return className;
  };

  const createThemeClassName = (className: string | undefined) => {
    return className ? `theme--${className?.replace(" ", "").toLocaleLowerCase()}` : "";
  };
  const isBrowser = typeof window !== "undefined";
  const url = isBrowser && window.location.href;
  const enableNewRelic =
    url && !url.includes("localhost") && !url.includes("deploy-preview") && !url.includes("staging.netlify");

  const CommonScripts = () => {
    if (filteredScriptsData?.length > 0) {
      return filteredScriptsData.map((script: any) => (
        <Helmet key={script.id}>
          <script type={script.type} async={script.async} src={script.src}></script>
        </Helmet>
      ));
    }
    return null;
  };

  return (
    <LocalizedContext.Provider value={localizedContext}>
      <div className={pageClassName(pageName)}>
        <Helmet>
          <html lang={language ? `${language}-${isoCountry}` : htmlLang} />
        </Helmet>
        {/* Don't put any scripts before OneTrust, CookieQ and Evidon! */}
        {enableOneTrust && <OneTrust />}
        {enableOneTrust && <CookieQ />}
        {enableOneTrust && <Evidon />}
        {/* Don't put any scripts before OneTrust, CookieQ and Evidon! */}
        <CommonScripts />
        <MikmakScript />
        {enableAnalytics && enableNewRelic && <NewRelic />}
        {enableAnalytics && <AdobeLaunch />}
        {enableAnalytics && (
          <Analytics
            pageName={pageName}
            pageType={pageType}
            subCategories={parentPages}
            contentType={contentType || pageName}
            richWebTags={localizedContext?.richWebTags}
          />
        )}
        <Header data={localizedContext} />
        <main
          className={createThemeClassName(theme?.name)}
          style={backgroundColor && { backgroundColor: backgroundColor.value }}
        >
          <div id="mainContent" className={cssClass || pageName?.toLowerCase().replace(/ /g, "-")}>
            {children}
          </div>
        </main>
        <Footer data={localizedContext} />
      </div>
    </LocalizedContext.Provider>
  );
};

export default Layout;

import React, { FunctionComponent } from "react";
import { InternalLink } from "../../types/SanityTypes";
import { Sphere } from "../../images/icons/sphere";
import { Link } from "../Link";
import Accessibility from "../Accessibility";
import "./styles.scss";

type CountryIdentifierProps = {
  location: InternalLink;
  locationText: string;
  locationHover?: string;
};

const CountryIdentifier: FunctionComponent<CountryIdentifierProps> = ({ location, locationText, locationHover }) => {
  return (
    <div className="country-identifier">
      <Sphere country={locationText} />
      {locationHover ? (
        <Link
          _id={location._id}
          to={location.slug.current}
          className="country"
          data-analytics-event5=""
          title={`${locationText} - ${locationHover}`}
        >
          {locationText}
          <Accessibility location={`${locationText} - ${locationHover}`} openNewWindow={false} />
        </Link>
      ) : (
        <Link
          _id={location._id}
          to={location.slug.current}
          className="country"
          data-analytics-event5=""
          title={locationText}
        >
          {locationText}
        </Link>
      )}
    </div>
  );
};

export default CountryIdentifier;

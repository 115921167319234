import React from "react";

export const Play = () => {
  return (
    <svg aria-hidden="true" focusable="false" width="80px" height="80px" viewBox="0 0 136 136" version="1.1">
      <title>Play</title>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-616.000000, -636.000000)">
          <g transform="translate(-2285.000000, 84.000000)">
            <g transform="translate(2773.000000, 424.000000)">
              <g transform="translate(130.000000, 130.000000)">
                <circle stroke="#FFFFFF" strokeWidth="4" opacity="0.8" cx="65.6" cy="65.6" r="65.6"></circle>
                <path
                  d="M95,65.0044494 C95,66.5492274 93.475,67.660021 93.475,67.660021 L50.67,95.0578737 C47.55,97.1709647 45,95.6055208 45,91.6014974 L45,38.4022349 C45,34.3878786 47.55,32.8327676 50.675,34.9406921 L93.48,62.3488778 C93.475,62.3488778 95,63.4596714 95,65.0044494 L95,65.0044494 Z"
                  fill="#FFFFFF"
                  strokeWidth="1"
                  opacity="0.8"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

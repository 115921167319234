import React from "react";
import "./styles.scss";

export const Search = () => {
  return (
    <div className="svg-fill">
      <svg
        className="search-icon"
        width="26"
        height="26"
        viewBox="0 0 26 26"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>Search</title>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-1228.000000, -48.000000)" strokeWidth="1.6">
            <g transform="translate(70.000000, 17.000000)">
              <g transform="translate(1159.000000, 32.000000)">
                <circle stroke="#140041" cx="9.6" cy="9.6" r="9.6"></circle>
                <line x1="16.8" y1="16.8" x2="24" y2="24" stroke="#140042" strokeLinecap="round"></line>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};

import { EventType, pushEvent } from "./index";

/**
 * Video Progress
 */
export const videoProgress = (
  videoName: string,
  videoId: string,
  videoProgressPercentage: number,
  videoSource = "Sanity"
) => {
  // @ts-ignore
  window.digitalData.video = [];
  // @ts-ignore
  window.digitalData.video.push({
    videoName,
    videoId
  });

  const eventLabel = `${videoSource} - ${videoProgressPercentage}%-${videoName} -${videoId}`;

  pushEvent({
    eventInfo: {
      type: EventType.trackEvent,
      eventAction: EventType.videoProgress,
      eventLabel: eventLabel,
      eventValue: 1
    },
    category: {
      primaryCategory: EventType.custom
    },
    subcategory: EventType.read,
    attributes: {
      nonInteractive: {
        nonInteraction: 1
      }
    }
  });
};

import React from "react";

export const Facebook = () => {
  return (
    <svg width="64px" height="64px" viewBox="0 0 64 64" version="1.1">
      <title>Facebook</title>
      <g id="Symbols-fb" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-964.000000, -95.000000)">
          <rect fill="none" x="0" y="0" width="1366" height="626"></rect>
          <g transform="translate(69.000000, 92.000000)"></g>
          <g transform="translate(964.000000, 95.000000)" fill="#FFFFFF">
            <path d="M32,0 C14.3266667,0 0,14.3266667 0,32 C0,49.6733333 14.3266667,64 32,64 C49.6733333,64 64,49.6733333 64,32 C64,14.3266667 49.6733333,0 32,0 L32,0 Z M39.58,22.1133333 L34.77,22.1133333 C34.2,22.1133333 33.5666667,22.8633333 33.5666667,23.86 L33.5666667,27.3333333 L39.5833333,27.3333333 L38.6733333,32.2866667 L33.5666667,32.2866667 L33.5666667,47.1566667 L27.89,47.1566667 L27.89,32.2866667 L22.74,32.2866667 L22.74,27.3333333 L27.89,27.3333333 L27.89,24.42 C27.89,20.24 30.79,16.8433333 34.77,16.8433333 L39.58,16.8433333 L39.58,22.1133333 L39.58,22.1133333 Z"></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

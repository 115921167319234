import normalize from "normalize-path";

export const stagingOrProd = (staging: string, prod: string) =>
  process.env.NODE_ENV === "development" ? staging : prod;

/**
 * 1. If a document _id is specified:
 *     1. If this document is in the base language, use the slug as is.
 *     2. Otherwise, prefix the path with the language code (e.g. "/fr").
 * 2. Normalize the slug, by adding a leading and trailing slash, then replacing any duplicate slashes ("//") with a
 * single slash.
 *
 * @param slug Sanity document slug
 * @param _id Sanity document ID. Optional.
 */
export const getLocalizedPath = (slug: string, _id?: string) => {
  let localizedPath = slug;

  if (_id && _id.indexOf("i18n") >= 0) {
    const matches = _id.match(/\.([^.]+)\.?$/);
    if (matches) {
      const language = matches[1].split("_")[0].toLowerCase();
      localizedPath = `${language}/${localizedPath}`;
    }
  }
  if (localizedPath && localizedPath.includes("?")) {
    return normalize(`/${localizedPath}`, false);
  }
  return normalize(`/${localizedPath}/`, false);
};

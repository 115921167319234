import React from "react";

export const Email = () => {
  return (
    <svg width="36px" height="36px" viewBox="0 0 27 27" version="1.1">
      <title>Share via Email</title>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-1224.000000, -159.000000)" fill="#140040">
          <path d="M1237.5,159 C1230.04413,159 1224,165.044193 1224,172.500043 C1224,179.955893 1230.04413,186 1237.5,186 C1244.95587,186 1251,179.955893 1251,172.500043 C1251,165.044193 1244.95587,159 1237.5,159 L1237.5,159 Z M1232.3124,168 L1242.68717,168 C1243.2374,168 1242.9542,168.742407 1242.65475,168.932967 C1242.3553,169.122819 1238.21031,171.757726 1238.05578,171.855486 C1237.90126,171.953245 1237.70126,172 1237.50008,172 C1237.29882,172 1237.09882,171.953245 1236.94438,171.855486 C1236.78985,171.757726 1232.64478,169.122819 1232.34533,168.932967 C1232.04596,168.742407 1231.76268,168 1232.3124,168 L1232.3124,168 Z M1243,176.301545 C1243,176.594842 1242.6539,177 1242.38887,177 L1232.61113,177 C1232.3461,177 1232,176.594842 1232,176.301545 L1232,171.207395 C1232,171.079163 1231.99765,170.912053 1232.23633,171.054608 C1232.5753,171.256505 1236.72656,173.743727 1236.93427,173.867867 C1237.1419,173.992006 1237.28751,174.006927 1237.48808,174.006927 C1237.68858,174.006927 1237.83418,173.992006 1238.04189,173.867867 C1238.2496,173.743728 1242.42471,171.257188 1242.76367,171.055291 C1243.00235,170.912735 1243,171.079846 1243,171.208078 L1243,176.301545 L1243,176.301545 Z"></path>
        </g>
      </g>
    </svg>
  );
};

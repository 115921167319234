import React from "react";

export const Twitter = () => {
  return (
    <svg width="64px" height="64px" viewBox="0 0 64 64" version="1.1">
      <title>Twitter</title>
      <g id="Symbols-twitter" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-1054.000000, -95.000000)">
          <rect fill="none" x="0" y="0" width="1366" height="626"></rect>
          <g transform="translate(69.000000, 92.000000)"></g>
          <g transform="translate(964.000000, 95.000000)" fill="#FFFFFF">
            <path d="M122,0 C104.326667,0 90,14.3266667 90,32 C90,49.6733333 104.326667,64 122,64 C139.673333,64 154,49.6733333 154,32 C154,14.3266667 139.673333,0 122,0 L122,0 Z M135.016667,26.2133333 C135.03,26.4866667 135.033333,26.76 135.033333,27.0266667 C135.033333,35.36 128.696667,44.9633333 117.103333,44.9633333 C113.543333,44.9633333 110.23,43.9233333 107.443333,42.13 C107.933333,42.19 108.436667,42.2133333 108.946667,42.2133333 C111.9,42.2133333 114.616667,41.21 116.773333,39.5166667 C114.016667,39.4633333 111.69,37.6433333 110.886667,35.1433333 C111.27,35.2133333 111.663333,35.2533333 112.07,35.2533333 C112.643333,35.2533333 113.203333,35.1766667 113.73,35.0333333 C110.846667,34.4533333 108.673333,31.9066667 108.673333,28.8533333 L108.673333,28.7766667 C109.523333,29.2466667 110.496667,29.5333333 111.53,29.5666667 C109.836667,28.4333333 108.726667,26.5066667 108.726667,24.3166667 C108.726667,23.1633333 109.036667,22.0766667 109.58,21.1466667 C112.69,24.96 117.33,27.4666667 122.57,27.7366667 C122.46,27.2733333 122.406667,26.7933333 122.406667,26.2966667 C122.406667,22.82 125.226667,19.9933333 128.71,19.9933333 C130.52,19.9933333 132.16,20.76 133.31,21.9866667 C134.746667,21.7 136.093333,21.18 137.31,20.4566667 C136.84,21.9266667 135.843333,23.1633333 134.54,23.9466667 C135.816667,23.79 137.03,23.4533333 138.16,22.95 C137.316667,24.2133333 136.246667,25.3266667 135.016667,26.2133333 L135.016667,26.2133333 Z"></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

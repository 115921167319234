import { useEffect } from "react";

const useScript = (url: string, id: string) => {
  useEffect(() => {
    if (!document.getElementById(id)) {
      const script = document.createElement("script");
      script.id = id;
      script.src = url;
      document.body.appendChild(script);
      return () => {
        document.body.removeChild(script);
      };
    }
  }, [url, id]);
};

export default useScript;

import React from "react";

export const Share = () => {
  return (
    <svg width="42px" height="42px" viewBox="0 0 42 42" version="1.1">
      <title>Share</title>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-866.000000, -151.000000)">
          <g transform="translate(867.000000, 152.000000)">
            <circle stroke="#140040" strokeWidth="2" cx="20" cy="20" r="20" />
            <polygon
              fill="#140040"
              points="19.6666667 22 21.3333333 22 21.3333333 14.3846154 23 14.3846154 20.5 11 18 14.3846154 19.6666667 14.3846154"
            />
            <path
              d="M26.125,18 L23.5,18 L23.5,19.6923077 L25.25,19.6923077 L25.25,27.3076923 L14.75,27.3076923 L14.75,19.6923077 L16.5,19.6923077 L16.5,18 L13.875,18 C13.391125,18 13,18.3782308 13,18.8461538 L13,28.1538462 C13,28.6209231 13.391125,29 13.875,29 L26.125,29 C26.608875,29 27,28.6209231 27,28.1538462 L27,18.8461538 C27,18.3790769 26.608875,18 26.125,18 L26.125,18 Z"
              fill="#140040"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
